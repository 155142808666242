import axios from 'axios';

export default {
    addContentLink(tenantId, params) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/ContentLinks', params);
    },

    deleteContentLink(tenantId, orgId, contentLinkId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/ContentLinks/' + contentLinkId);
    },

    getContentLinksForOrg(tenantId, orgId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/ContentLinks');
    },

    getContentLinksForOrgByParentEntity(tenantId, orgId, parentEntityId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/ContentLinks/' + parentEntityId);
    },

    getContentLinksForOrgByChildEntity(tenantId, orgId, childEntityId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/ContentLinks/' + childEntityId + '?findParents=true');
    },

    getGuidancePackContentsForSelf(guidancePackId) {
        return axios.get(window.API_BASE + '/api/v1/GuidancePacks/' + guidancePackId);
    },

    getGuidancePacksForOrg(tenantId, orgId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Orgs/' + orgId + '/GuidancePacks');
    }
}