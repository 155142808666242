<template>
  <div style="display: inline">
    <a-button type="primary" icon="plus" @click.prevent="openModal"
      >Add Existing</a-button
    >

    <a-modal
      v-model="visible"
      title="Link Content"
      :footer="null"
      centered
      :width="800"
    >
      <loading-screen :is-loading="isLinking"></loading-screen>

      <!-- Loading -->
      <div class="loader" v-if="isLoadingOptions">
        <a-spin></a-spin>
      </div>
      <!-- / Loading -->

      <!-- Search -->
      <a-input-search
        v-if="!isLoadingOptions"
        placeholder="Search..."
        style="width: 230px; margin-bottom: 20px"
        v-model="searchQuery"
      />

      <!-- / Search -->

      <!-- No Options -->
      <div v-if="!isLoadingOptions && optionsToShow.length == 0">
        <a-alert type="error" banner message="No content to show" />
      </div>
      <!-- / No Option -->

      <!-- Options -->
      <div
        v-if="!isLoadingOptions && optionsToShow.length"
        class="options-wrapper"
      >
        <a-row :gutter="10" type="flex">
          <a-col :span="12" v-for="(option, i) in optionsToShow" :key="i">
            <div class="option" @click.prevent="linkOption(option)">
              {{ option["$v"]["displayName"] }}
            </div>
          </a-col>
        </a-row>
      </div>
      <!-- / Options -->
    </a-modal>
  </div>
</template>

<script>
const _ = require("lodash");
import { mapActions, mapGetters } from "vuex";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import contentLinks from "../../../../api/guidance/content-links";

export default {
  props: [
    "tenantId",
    "currentContentLinks",
    "orgId",
    "parentType",
    "parentTypeValue",
    "childType",
    "childTypeValue",
    "parentId",
    "linkingChildOrParent",
  ],
  components: { LoadingScreen },
  data() {
    return {
      visible: false,
      isLoadingOptions: false,
      isLinking: false,
      searchQuery: "",
    };
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.searchQuery = "";
        this.isLinking = false;
        this.loadOptions();
      }
    },
  },
  computed: {
    ...mapGetters("adminArticles", {
      articlesToShow: "articlesToShow",
    }),

    ...mapGetters("adminTopics", {
      topicsToShow: "topicsToShow",
    }),

    ...mapGetters("adminHelpVideos", {
      helpVideosToShow: "helpVideosToShow",
    }),

    ...mapGetters("adminDownloads", {
      downloadsToShow: "downloadsToShow",
    }),

    ...mapGetters("adminExternalLinks", {
      externalLinksToShow: "externalLinksToShow",
    }),

    options() {
      if (this.childType == "Article") {
        return this.articlesToShow;
      }
      if (this.childType == "Topic") {
        return this.topicsToShow;
      }
      if (this.childType == "Help Video") {
        return this.helpVideosToShow;
      }
      if (this.childType == "Policy") {
        return this.downloadsToShow;
      }
      if (this.childType == "External Link") {
        return this.externalLinksToShow;
      }
      return [];
    },

    idsOfCurrentlyLinkedChildren() {
      return _.map(this.currentContentLinks, "$v.childEntityId");
    },

    idsOfCurrentlyLinkedParents() {
      return _.map(this.currentContentLinks, "$v.parentEntityId");
    },

    unlinkedOptions() {
      return _.filter(this.options, (option) => {
        return !_.includes(
          this.linkingChildOrParent == "child"
            ? this.idsOfCurrentlyLinkedChildren
            : this.idsOfCurrentlyLinkedParents,
          option["$v"]["id"]
        );
      });
    },

    optionsToShow() {
      if (this.searchQuery && this.searchQuery.trim().length) {
        return _.filter(this.unlinkedOptions, (option) => {
          return option["$v"].displayName
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });
      }
      return this.unlinkedOptions;
    },
  },
  methods: {
    ...mapActions("adminArticles", {
      loadArticles: "loadArticles",
    }),

    ...mapActions("adminTopics", {
      loadTopics: "loadTopics",
    }),

    ...mapActions("adminHelpVideos", {
      loadHelpVideos: "loadHelpVideos",
    }),

    ...mapActions("adminDownloads", {
      loadDownloads: "loadDownloads",
    }),

    ...mapActions("adminExternalLinks", {
      loadExternalLinks: "loadExternalLinks",
    }),

    openModal() {
      this.visible = true;
    },

    closeModal() {
      this.visible = false;
    },

    async loadOptions() {
      let vm = this;
      vm.isLoadingOptions = true;
      if (this.childType == "Article") {
        await this.loadArticles();
      }
      if (this.childType == "Topic") {
        await this.loadTopics();
      }
      if (this.childType == "Help Video") {
        await this.loadHelpVideos();
      }
      if (this.childType == "Policy") {
        await this.loadDownloads();
      }
      if (this.childType == "External Link") {
        await this.loadExternalLinks();
      }
      vm.isLoadingOptions = false;
    },

    async linkOption(option) {
      let vm = this;
      vm.isLinking = true;

      let params;
      if (this.linkingChildOrParent == "child") {
        params = {
          parentEntityId: vm.parentId,
          parentEntityType: vm.parentTypeValue,
          childEntityId: option["$v"].id,
          childEntityType: vm.childTypeValue,
          ownerId: vm.orgId,
          displayName: option["$v"].displayName,
          rank: 0,
          scope: 1, // shown to organisation
        };
      } else {
        params = {
          childEntityId: vm.parentId,
          childEntityType: vm.parentTypeValue,
          parentEntityId: option["$v"].id,
          parentEntityType: vm.childTypeValue,
          ownerId: vm.orgId,
          displayName: option["$v"].displayName,
          rank: 0,
          scope: 1, // shown to organisation
        };
      }

      try {
        await contentLinks.addContentLink(vm.tenantId, params);
        vm.$message.success("Content linked successfully");
        vm.isLinking = false;
        vm.$emit("refresh");
        vm.closeModal();
      } catch (e) {
        vm.isLinking = false;
        vm.$message.error("Error linking option");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.loader {
  padding: 30px;
  text-align: center;
}

.options-wrapper {
  max-height: 500px;
  overflow-y: scroll;
  padding-right: 10px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .option {
    padding: 15px 20px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid #eee;

    margin-bottom: 10px;

    &:hover {
      color: #5155ea;
      border: 1px solid #5155ea;
    }
  }
}
</style>