<template>
  <div style="display: inline">
    <a-button type="primary" icon="plus" @click.prevent="openModal"
      >Create New</a-button
    >

    <a-modal v-model="visible" :title="modalTitle" :footer="null">
      <loading-screen :is-loading="isLinking"></loading-screen>

      <div :key="updateKey">
        <create-article-form-inline
          v-if="childType == 'Article'"
          @article-created="linkArticle"
        ></create-article-form-inline>
      </div>
    </a-modal>
  </div>
</template>

<script>
import CreateArticleFormInline from "./CreateAndLinkButtonAndModal/CreateArticleFormInline.vue";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import contentLinks from "../../../../api/guidance/content-links";

export default {
  components: { CreateArticleFormInline, LoadingScreen },
  props: [
    "tenantId",
    "orgId",
    "childType",
    "childTypeValue",
    "parentType",
    "parentTypeValue",
    "parentId",
    "linkingChildOrParent",
  ],

  data() {
    return {
      visible: false,
      isLinking: false,
      updateKey: 1
    };
  },

  computed: {
    modalTitle() {
      return "Create And Link New " + this.childType;
    },
  },

  methods: {
    openModal() {
      this.visible = true;
      this.updateKey += 1;
    },

    closeModal() {
      this.visible = false;
    },

    async linkArticle(option) {
      let vm = this;
      vm.isLinking = true;

      let params;
      if (this.linkingChildOrParent == "child") {
        params = {
          parentEntityId: vm.parentId,
          parentEntityType: vm.parentTypeValue,
          childEntityId: option["$v"].id,
          childEntityType: vm.childTypeValue,
          ownerId: vm.orgId,
          displayName: option["$v"].displayName,
          rank: 0,
          scope: 1, // shown to organisation
        };
      } else {
        params = {
          childEntityId: vm.parentId,
          childEntityType: vm.parentTypeValue,
          parentEntityId: option["$v"].id,
          parentEntityType: vm.childTypeValue,
          ownerId: vm.orgId,
          displayName: option["$v"].displayName,
          rank: 0,
          scope: 1, // shown to organisation
        };
      }

      try {
        await contentLinks.addContentLink(vm.tenantId, params);
        vm.$message.success("Content linked successfully");
        vm.isLinking = false;
        vm.$emit("refresh");
        vm.closeModal();
      } catch (e) {
        vm.isLinking = false;
        vm.$message.error("Error linking option");
      }
    },
  },
};
</script>

<style>
</style>