<template>
  <div class="create-article-form-inline">
    <!-- Form -->
    <div v-if="!isLoading" class="form">
      <div class="top-row">
        <div class="left">
          <a-form-item label="Button Strip">
            <a-upload
              :multiple="false"
              :file-list="fileList"
              :before-upload="beforeUpload"
              :remove="handleRemove"
            >
              <a-button>
                <a-icon type="upload"></a-icon>
                Click to Upload
              </a-button>
            </a-upload>
          </a-form-item>
        </div>
        <div class="right">
          <a-form-item label="Article Name">
            <a-input size="large" v-model="articleLocal.displayName"></a-input>
          </a-form-item>
        </div>
      </div>

      <a-form-item label="Context Quote">
        <a-input size="large" v-model="articleLocal.contextQuote"></a-input>
      </a-form-item>

      <a-form-item label="Visibility">
        <a-radio-group v-model="articleLocal.scope">
          <a-radio :value="1">Shown to organisation</a-radio>
          <!-- <a-radio :value="2">Shown to whole tenant</a-radio> -->
          <a-radio :value="3">Hidden from lists</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="Display Order">
        <a-radio-group v-model="articleLocal.rank">
          <a-radio v-for="(rank, i) in ranks" :key="i" :value="rank.rank">{{
            rank.label
          }}</a-radio>
        </a-radio-group>
      </a-form-item>
    </div>
    <!-- / Form -->

    <a-button
      @click.prevent="save"
      class="btn-rounded save-button"
      size="large"
      type="primary"
      >Save</a-button
    >
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
const _ = require("lodash");
import guidanceHelpers from "../../../../../helpers/guidance";
import articles from "../../../../../api/guidance/articles";
export default {
  data() {
    return {
      articleLocal: {
        displayName: "",
        contextQuote: "",
        scope: 1,
        rank: 0,
      },

      fileList: [],

      isSaving: false,
    };
  },
  methods: {
    ...mapActions('adminArticles', {
      loadArticles: 'loadArticles'
    }),

    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },

    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("Please upload a JPG or a PNG");
      }
      if (isJpgOrPng) {
        this.fileList = [file];
      }
      return false;
    },

    getValidationErrors() {
      let errors = [];
      if (this.articleLocal.displayName.trim().length == 0) {
        errors.push("Please provide a article name");
      }
      if (this.articleLocal.contextQuote.trim().length == 0) {
        errors.push("Please provide a context quote");
      }
      return errors;
    },

    async save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      vm.isSaving = true;

      let params = {
        ...this.articleLocal,
        ownerId: this.selectedOrganisation.id,
      };

      articles
        .addArticle(vm.tenantId, params)
        .then((r) => {
          vm.$message.success("Article created successfully");
          if (this.fileList.length) {
            articles
              .addArticleButtonStrip(
                vm.tenantId,
                r.data["$v"].id,
                vm.fileList[0]
              )
              .then(() => {
                vm.loadArticles();
                vm.$emit("article-created", r.data);
                // vm.$router.push("/admin/guidance/articles");
              })
              .catch((e) => {
                console.log(e);
                vm.$router.push("/admin/guidance/articles");
                vm.$message.error("Error uploading button strip");
              });
          } else {
            vm.loadArticles();
            vm.$emit("article-created", r.data);
            // vm.$router.push("/admin/guidance/articles");
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  computed: {
    ...mapGetters("missionControl", {
      presences: "presences",
    }),

    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      isLoading: "isLoading",
    }),

    myPresenceForOrg() {
      return _.find(this.presences, {
        ownerId: this.selectedOrganisation.id,
      });
    },

    ranks() {
      return guidanceHelpers.listRankOptions();
    },
  },
};
</script>

<style lang="scss">
.create-article-form-inline {
  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .top-row {
    display: flex;
    .left {
      flex-shrink: 1;
    }
    .right {
      padding-left: 20px;
      flex-grow: 1;
    }
  }

  .form {
    // background: #fff;
    // padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    // border: 1px solid #fff;
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 5px;
  }
}
</style>