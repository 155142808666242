<template>
  <div>
    <loading-screen :is-loading="isUnlinking"></loading-screen>

    <a-tabs v-model="selectedTab">
      <a-tab-pane
        key="details"
        tab="Details"
        v-if="showDetailsTab"
      ></a-tab-pane>
      <a-tab-pane
        v-for="tab in tabs"
        :key="tab.key"
        :tab="tab.label"
      ></a-tab-pane>
    </a-tabs>

    <!-- Basic details -->
    <div v-if="selectedTab == 'details'">
      <slot></slot>
    </div>
    <!-- / Basic details -->

    <!-- Content links -->
    <div v-if="selectedTab !== 'details'">
      <!-- No content links -->
      <a-alert
        v-if="contentLinksForSelectedTab.length === 0"
        type="info"
        message="Nothing to show here yet"
      />
      <!-- / No content links -->

      <div
        class="content-link-wrapper"
        v-for="contentLink in contentLinksForSelectedTab"
        :key="contentLink['$v']['id']"
      >
        <content-link
        :can-edit="canEdit"
          :content-link="contentLink"
          :type="selectedTabType"
          :tenant-id="tenantId"
          :org-id="orgId"
          @unlink="unlinkContentLink(contentLink)"
          :shows-parents-or-children="selectedTabShowsParentsOrChildren"
        ></content-link>
      </div>

      <div class="content-link-button-and-modal-wrapper" v-if="canEdit !== false">
        <add-content-link-button-and-modal
          :tenant-id="tenantId"
          :current-content-links="contentLinksForSelectedTab"
          :org-id="orgId"
          :child-type="selectedTabType"
          :child-type-value="selectedTabTypeValue"
          :parent-type="parentType"
          :parent-type-value="parentTypeValue"
          :parent-id="parentId"
          @refresh="$emit('refresh')"
          :linking-child-or-parent="
            selectedTabShowsParentsOrChildren == 'children' ? 'child' : 'parent'
          "
        ></add-content-link-button-and-modal>

        <create-and-link-button-and-modal
          style="margin-left: 10px"
          v-if="shouldShowCreate"
          :tenant-id="tenantId"
          :org-id="orgId"
          :child-type="selectedTabType"
          :child-type-value="selectedTabTypeValue"
          :parent-type="parentType"
          :parent-type-value="parentTypeValue"
          :parent-id="parentId"
          @refresh="$emit('refresh')"
          :linking-child-or-parent="
            selectedTabShowsParentsOrChildren == 'children' ? 'child' : 'parent'
          "
        ></create-and-link-button-and-modal>
      </div>
    </div>
    <!-- / Content links -->
  </div>
</template>

<script>
const _ = require("lodash");
import contentLinkHelpers from "../../../helpers/content-links";
import ContentLink from "./ContentLinkTabs/ContentLink.vue";
import contentLinks from "../../../api/guidance/content-links";
import LoadingScreen from "../../../components/LoadingScreen.vue";
import AddContentLinkButtonAndModal from "./ContentLinkTabs/AddContentLinkButtonAndModal.vue";
import CreateAndLinkButtonAndModal from "./ContentLinkTabs/CreateAndLinkButtonAndModal.vue";

const AVAILABLE_TABS = [
  {
    key: "articles",
    label: "Articles",
  },
  {
    key: "topics",
    label: "Topics",
  },
  {
    key: "help-videos",
    label: "Help Videos",
  },
  {
    key: "downloads",
    label: "Downloads",
  },
  {
    key: "links",
    label: "Links",
  },
  {
    key: "playbooks",
    label: "Playbooks",
  },
];

export default {
  components: { ContentLink, LoadingScreen, AddContentLinkButtonAndModal, CreateAndLinkButtonAndModal },
  props: [
    "tenantId",
    "orgId",
    "parentId",
    "parentType",
    "contentLinks",
    "isLoading",
    "types",
    "canCreate",
    "showDetailsTab",
    "childrenOf",
    "canEdit"
  ],
  data() {
    return {
      selectedTab: this.showDetailsTab ? "details" : _.first(this.types),

      isUnlinking: false,
    };
  },
  computed: {
    tabs() {
      let tabs = [];
      _.each(AVAILABLE_TABS, (tab) => {
        if (this.types.includes(tab.key)) {
          tabs.push(tab);
        }
      });
      return tabs;
    },

    shouldShowCreate() {
      if (this.canCreate && this.canCreate.length) {
        return this.canCreate.includes(this.selectedTab);
      }
      return false;
    },

    selectedTabType() {
      let type = null;

      if (this.selectedTab == "help-videos") {
        type = "Help Video";
      }

      if (this.selectedTab == "policies" || this.selectedTab == "downloads") {
        type = "Policy";
      }

      if (this.selectedTab == "articles") {
        type = "Article";
      }

      if (this.selectedTab == "external-links" || this.selectedTab == "links") {
        type = "External Link";
      }

      if (this.selectedTab == "topics") {
        type = "Topic";
      }

      if (this.selectedTab == "guidance-packs") {
        type = "GuidancePack";
      }

      if (this.selectedTab == "checklists") {
        type = "Checklist";
      }

      return type;
    },

    selectedTabTypeValue() {
      let typeValue = contentLinkHelpers.getEntityTypeValueForType(
        this.selectedTabType
      );
      return typeValue;
    },

    selectedTabShowsParentsOrChildren() {
      if (this.childrenOf && this.childrenOf.includes(this.selectedTab)) {
        return "parents";
      }
      return "children";
    },

    contentLinksForSelectedTab() {
      let typeValue = this.selectedTabTypeValue;
      if (typeValue) {
        if (this.selectedTabShowsParentsOrChildren == "children") {
          return _.filter(this.contentLinks, (cl) => {
            return cl["$v"]["childEntityType"] == typeValue;
          });
        } else {
          return _.filter(this.contentLinks, (cl) => {
            return cl["$v"]["parentEntityType"] == typeValue;
          });
        }
      }

      return [];
    },

    parentTypeValue() {
      let typeValue = contentLinkHelpers.getEntityTypeValueForType(
        this.parentType
      );
      return typeValue;
    },
  },
  methods: {
    async unlinkContentLink(contentLink) {
      let vm = this;
      vm.isUnlinking = true;

      try {
        await contentLinks.deleteContentLink(
          vm.tenantId,
          vm.orgId,
          contentLink["$v"]["id"]
        );
        vm.$message.success("Unlinked successfully");
        vm.isUnlinking = false;
        vm.$emit("refresh");
      } catch (e) {
        console.log(e);
        vm.isUnlinking = false;
        vm.$message.error("Error unlinking content");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.content-link-button-and-modal-wrapper {
  margin-top: 20px;
}

.content-link-wrapper {
  margin-bottom: 15px;
}
</style>