const _ = require('lodash');

const ENTITY_VIEW_OBJECT_TYPES = [
    {
        type: 'Unknown',
        value: 0
    },
    {
        type: 'Help Video',
        value: 1
    },
    {
        type: 'Policy',
        value: 2
    },
    {
        type: 'Article',
        value: 3
    },
    {
        type: 'External Link',
        value: 4
    },
    {
        type: 'Topic',
        value: 5
    },
    {
        type: 'Guidance Pack',
        value: 6
    },
    {
        type: 'Checklist',
        value: 7
    }
];

export default {
    listEntityViewObjectTypes() {
        return ENTITY_VIEW_OBJECT_TYPES;
    },

    getEntityTypeValueForType(type) {
        let option = _.find(ENTITY_VIEW_OBJECT_TYPES, {type: type});
        return option ? option.value : null;
    }
}