<template>
  <div class="content-link-list-entry">
    <div class="left">
      <span v-if="isLoading">Loading...</span>
      <div v-if="!isLoading && child">
        <div class="name" @click.prevent="viewContentLink">
          {{ child["$v"]["displayName"] }}
        </div>
        <div v-if="child['$v']['contextQuote']">
          {{ child["$v"]["contextQuote"] }}
        </div>
      </div>
    </div>
    <div class="right" v-if="canEdit !== false">
      <a-popconfirm
        title="Are you sure?"
        ok-text="Yes"
        cancel-text="No"
        @confirm="$emit('unlink')"
      >
        <a-button type="primary" icon="link">Unlink</a-button>
      </a-popconfirm>
    </div>
  </div>
</template>

<script>
import articles from "../../../../api/guidance/articles";
import topics from "../../../../api/guidance/topics";
import helpVideos from "../../../../api/guidance/help-videos";
import policies from "../../../../api/guidance/policies";
import externalLinks from "../../../../api/guidance/external-links";
const _ = require("lodash");
export default {
  props: ["contentLink", "type", "tenantId", "orgId", "showsParentsOrChildren", "canEdit"],
  data() {
    return {
      isLoading: false,
      child: null,
    };
  },
  created() {
    this.loadChild();
  },
  computed: {
    childId() {
      if (this.showsParentsOrChildren == 'children') {
        return this.contentLink["$v"]["childEntityId"];
      } else {
        return this.contentLink["$v"]["parentEntityId"];
      }
    },
  },
  methods: {
    viewContentLink() {
      let backTo = this.$route.path;
      if (this.type == 'Article') {
        this.$router.push(
          '/admin/guidance/articles/' + this.childId + '?backTo=' + backTo
        );
      }
      if (this.type == 'Topic') {
        this.$router.push(
          '/admin/guidance/topics/' + this.childId + '?backTo=' + backTo
        );
      }
      if (this.type == 'Help Video') {
        this.$router.push(
          '/admin/guidance/help-videos/' + this.childId + '?backTo=' + backTo
        );
      }
      if (this.type == 'Policy') {
        this.$router.push(
          '/admin/guidance/policies/' + this.childId + '?backTo=' + backTo
        );
      }
      if (this.type == 'External Link') {
        this.$router.push(
          '/admin/guidance/external-links/' + this.childId + '?backTo=' + backTo
        );
      }
    },

    async loadChild() {
      let vm = this;
      vm.isLoading = true;

      if (this.type == "Article") {
        try {
          this.child = await articles
            .getArticle(this.tenantId, this.childId)
            .then((r) => r.data);
        } catch (e) {
          console.log(e);
        }
      }

      if (this.type == "Topic") {
        try {
          this.child = await topics
            .getTopic(this.tenantId, this.childId)
            .then((r) => r.data);
        } catch (e) {
          console.log(e);
        }
      }

      if (this.type == "Help Video") {
        try {
          this.child = await helpVideos
            .getHelpVideos(this.tenantId, vm.orgId)
            .then((r) =>
              _.find(r.data, (video) => {
                return video["$v"]["id"] == vm.childId;
              })
            );
        } catch (e) {
          console.log(e);
        }
      }

      if (this.type == "Policy") {
        try {
          this.child = await policies
            .getPolicy(this.tenantId, this.childId)
            .then((r) => r.data);
        } catch (e) {
          console.log(e);
        }
      }

      if (this.type == "External Link") {
        try {
          this.child = await externalLinks
            .getExternalLink(this.tenantId, this.childId)
            .then((r) => r.data);
        } catch (e) {
          console.log(e);
        }
      }

      vm.isLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.content-link-list-entry {
  padding: 20px 25px;
  border-radius: 6px;
  background: #fff;

  display: flex;

  .left {
    flex-grow: 1;
  }

  .right {
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }

  .name {
    cursor: pointer;
    font-weight: 500;
    margin-bottom: 5px;

    &:hover {
      color: #5155ea;
    }
  }
}
</style>